<template>
	<Layout>
		<template>
			<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(updateProfile)">
					<article class="profile__column panel">
						<div class="panel__header">
							<span class="is-flex is-flex-direction-column is-justify-content-center">
								<h3 class="profile__name pt-0 is-semibold is-size-4">{{ $t('menu.security') }}</h3>
								<p class="is-size-6">{{ $t('profile.security') }}</p>
							</span>
							<b-button v-if="isDesktop" id="button-profile-password" tabindex="6" native-type="submit" type="is-primary save" :loading="loading" rounded>{{ $t('buttons.save') }}</b-button>
						</div>
						<div class="panel__body">
							<h3 class="profile__section mb-5 has-text-primary is-semibold is-size-5">{{ $t('auth.change_password') }}</h3>

							<InputWithValidation class="profile__field" tab="1" rules="required|min:8" field="password" :label="$t('fields.current_password')" name="current-password" size="is-medium" password-reveal v-model="user.old_password">
							</InputWithValidation>

							<InputWithValidation class="profile__field" tab="2" rules="required|min:8" field="password" :label="$t('fields.new_password')" name="new-password" size="is-medium" vid="new_password" password-reveal v-model="user.password">
								<password-meter :password="user.password" />
							</InputWithValidation>

							<InputWithValidation class="profile__field" tab="3" rules="required|min:8|confirmed:new_password" field="password" :label="$t('fields.confirm_password')"  name="password" size="is-medium" password-reveal v-model="user.confirm_password">
								<password-meter :password="user.confirm_password" />
							</InputWithValidation>

							<div class="is-flex is-justify-content-flex-end mb-3">
								<b-button v-if="!isDesktop" id="button-profile-password" tabindex="6" native-type="submit" type="is-primary save" :loading="loading" rounded>{{ $t('buttons.save') }}</b-button>
							</div>
						</div>
					</article>
				</form>
			</ValidationObserver>
		</template>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Profile'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import PasswordMeter from 'vue-simple-password-meter'
import Api from '@/services/api'
import { ValidationObserver } from 'vee-validate'
import { successToast, errorToast } from '@/mixins/toast'
import { responsive } from '@/mixins/responsive'

export default {
	name: 'Password',
	mixins: [responsive],
	components: {
		Layout,
		InputWithValidation,
		ValidationObserver,
		PasswordMeter
	},
	data() {
		return {
			loading: false,
			user: {}
		}
	},
	methods: {
		async updateProfile() {
			try {
				this.loading = true
				const response = await Api.put(`users/password`, this.user)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					successToast(this.$t('alerts.update.success_f', [this.$t('fields.password')]))
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(message)
				}
			} finally {
				this.loading = false
			}
		}
	}
}
</script>
